@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --charcoal: #1f2733;
  /* --light-charcoal: #1c2735; */
  --orange: #ffcc00;
  /* --charcoal: #1f27338c; */
  /* --dark-charcoal: #131b25; */
  --dark-charcoal: #101a25;
  --darker-charcoal: #0c1016;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.top-nav {
  display: flex;
  align-items: center !important;
}

.row-input {
  align-items: center !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3);
  transition: 0.25s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
