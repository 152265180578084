@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --charcoal: #1f2733;
  /* --light-charcoal: #1c2735; */
  --orange: #ffcc00;
  /* --charcoal: #1f27338c; */
  /* --dark-charcoal: #131b25; */
  --dark-charcoal: #101a25;
  --darker-charcoal: #0c1016;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.top-nav {
  display: flex;
  align-items: center !important;
}

.row-input {
  align-items: center !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000133;
}

body {
  background-color: var(--dark-charcoal) !important;
}

#nav {
  width: 100%;
  float: left;
  background-color: #fff;
  margin: 0 0 3em 0;
  padding: 0;
  list-style: none;
}

.customerdetailstable {
}

.customerdetailstable table {
  border-collapse: collapse;
}

.customerdetailstable th {
  border-block: 1px solid #e0e0e033;
  padding-block: 10px;
}

#nav li {
  float: left;
}

#nav li a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: bold;
  color: #069;
  border-right: 1px solid #ccc;
}

#nav li a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: bold;
  color: #069;
  border-right: 1px solid #ccc;
}

.small-container {
  border-radius: 2px;
  height: 20px;
  width: 20px;
  background-color: grey;
}

.add-circle {
  border-radius: 200px;
  background-color: rgba(128, 128, 128, 0.2);
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.logo {
  border-radius: 600px;
  width: 150px;
  padding: auto;
  flex: 1 1;
  height: 150px;
  margin: auto;
}

.logo-small {
  padding: 20px;
  width: 100px;
  height: 100px;
}

.input {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 0px none;
  background-color: #a49f9f;
  width: 100%;
  background: #e9f4f9;
  border-radius: 12px;
}

input:focus {
  outline: none !important;
  border-color: #719ece;
}

.padding {
  margin-left: 20px;
  margin-right: 20px;
}

.float-right {
  float: right;
  position: absolute;
  right: 0;
  flex: 1 1;
  margin-left: auto;
  margin-right: 30px;
  justify-content: flex-end;
}

.text-field {
  padding-top: 12px;
  border: 0px none;
  padding-bottom: 12px;
  border-radius: 12px;
}

.text-field:focus {
  outline: none !important;
  border-color: #719ece;
}

/* .search-bar {
  background: #e9f4f9;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
} */

.search-bar:hover {
  background: #e9f4f9;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}

.pagination-number {
  border-radius: 10px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: #ffc000;
}

.topnav {
  margin-top: 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.column {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.center {
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.chip {
  background-color: rgba(0, 255, 0, 0.4);
  border-radius: 5px;
  padding-left: 10px;
  color: green;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

th {
  text-align: left;
}

.align-left {
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.align-bottom {
  align-items: baseline;
}

.small-logo {
  border-radius: 600px;
  padding: 10px;
  width: 70px;
  height: 70px;
}

.space-between {
  justify-content: space-between;
}

/* .header,
.footer {
  padding-left: 200px;
  background: #000;
  width: 100%;
  z-index: 1;
  background-color: #000;
  color: #fff;
  position: fixed;
} */

.container {
  display: flex;
  flex: 1 1;
}

.expanded {
  width: 100%;
}

.sidebar {
  background: #fff;
  position: fixed;
  left: 0;
  z-index: 2;
  width: 1500px;
}

.sidebar::before {
  display: none;
}

.text-field {
  width: 100%;
  border-radius: 12px;
}

.boxes {
  border-radius: 42px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-content: center;
  background-color: #ffc000;
}

.boxes-pink {
  border-radius: 12px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 180px;
  background-color: #ffe3e1;
}

/* .main {
  flex: 1;
  margin-right: 20px;
  margin-left: 210px;
  margin-bottom: 100px;
  background: #000;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  text-align: center;
  margin: 0;
  padding: 0;
}

.pagination .MuiPaginationItem-page.Mui-selected {
  background-color: #ffc000;
  font-weight: bold;
  color: white;
}
.dashboard-container {
  background: #000;
  color: #fff;
}

#header {
  position: absolute;
  width: 30%;
  min-width: 150px;
  max-width: 200px;
}

.sidebar-main {
  background-color: #e9f4f9;
}

#header .pro-sidebar {
  height: 100vh;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: #e9f4f9;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  color: f00;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  align-content: center;
  text-align: center;
  font-weight: bold;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #ffcc0055;
  border-radius: 8px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in-out;
  padding: 0;
}

#header .pro-sidebar-inner .pro-sidebar-layout .inactive:hover {
  background-color: rgba(128, 128, 128, 0.096);
  border-radius: 8px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in-out;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active:hover {
  background-color: #ffffffcc;
  border-radius: 11px;
  margin-right: 12px;
  margin-left: 12px;
}

#header .logo {
  padding: 10px;
  margin-top: 1rem;
  background-color: #fff;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

.item-img {
  width: 20px;
  margin-right: 1rem;
}
.AppSidebar.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: static;
}
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 0.2rem;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0;
  padding-left: 1rem;
  margin: 0;
}
.pro-item-content {
  display: flex;
  align-items: center;
}

.create-input {
    background: #ffffff;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    /* font-size: medium; */
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    margin: 1rem 0;
}

.create-input:hover {
    border-radius: 10px;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.19);
}

.error-input {
    border: red 1px solid;
}

