.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000133;
}

body {
  background-color: var(--dark-charcoal) !important;
}

#nav {
  width: 100%;
  float: left;
  background-color: #fff;
  margin: 0 0 3em 0;
  padding: 0;
  list-style: none;
}

.customerdetailstable {
}

.customerdetailstable table {
  border-collapse: collapse;
}

.customerdetailstable th {
  border-block: 1px solid #e0e0e033;
  padding-block: 10px;
}

#nav li {
  float: left;
}

#nav li a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: bold;
  color: #069;
  border-right: 1px solid #ccc;
}

#nav li a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  font-weight: bold;
  color: #069;
  border-right: 1px solid #ccc;
}

.small-container {
  border-radius: 2px;
  height: 20px;
  width: 20px;
  background-color: grey;
}

.add-circle {
  border-radius: 200px;
  background-color: rgba(128, 128, 128, 0.2);
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.logo {
  border-radius: 600px;
  width: 150px;
  padding: auto;
  flex: 1;
  height: 150px;
  margin: auto;
}

.logo-small {
  padding: 20px;
  width: 100px;
  height: 100px;
}

.input {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 0px none;
  background-color: #a49f9f;
  width: 100%;
  background: #e9f4f9;
  border-radius: 12px;
}

input:focus {
  outline: none !important;
  border-color: #719ece;
}

.padding {
  margin-left: 20px;
  margin-right: 20px;
}

.float-right {
  float: right;
  position: absolute;
  right: 0;
  flex: 1;
  margin-left: auto;
  margin-right: 30px;
  justify-content: flex-end;
}

.text-field {
  padding-top: 12px;
  border: 0px none;
  padding-bottom: 12px;
  border-radius: 12px;
}

.text-field:focus {
  outline: none !important;
  border-color: #719ece;
}

/* .search-bar {
  background: #e9f4f9;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
} */

.search-bar:hover {
  background: #e9f4f9;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}

.pagination-number {
  border-radius: 10px;
  width: min-content;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: #ffc000;
}

.topnav {
  margin-top: 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.column {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.center {
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.chip {
  background-color: rgba(0, 255, 0, 0.4);
  border-radius: 5px;
  padding-left: 10px;
  color: green;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

th {
  text-align: left;
}

.align-left {
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.align-bottom {
  align-items: baseline;
}

.small-logo {
  border-radius: 600px;
  padding: 10px;
  width: 70px;
  height: 70px;
}

.space-between {
  justify-content: space-between;
}

/* .header,
.footer {
  padding-left: 200px;
  background: #000;
  width: 100%;
  z-index: 1;
  background-color: #000;
  color: #fff;
  position: fixed;
} */

.container {
  display: flex;
  flex: 1;
}

.expanded {
  width: 100%;
}

.sidebar {
  background: #fff;
  position: fixed;
  left: 0;
  z-index: 2;
  width: 1500px;
}

.sidebar::before {
  display: none;
}

.text-field {
  width: 100%;
  border-radius: 12px;
}

.boxes {
  border-radius: 42px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-content: center;
  background-color: #ffc000;
}

.boxes-pink {
  border-radius: 12px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 180px;
  background-color: #ffe3e1;
}

/* .main {
  flex: 1;
  margin-right: 20px;
  margin-left: 210px;
  margin-bottom: 100px;
  background: #000;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  text-align: center;
  margin: 0;
  padding: 0;
}

.pagination .MuiPaginationItem-page.Mui-selected {
  background-color: #ffc000;
  font-weight: bold;
  color: white;
}
.dashboard-container {
  background: #000;
  color: #fff;
}
