#header {
  position: absolute;
  width: 30%;
  min-width: 150px;
  max-width: 200px;
}

.sidebar-main {
  background-color: #e9f4f9;
}

#header .pro-sidebar {
  height: 100vh;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: #e9f4f9;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  color: f00;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  align-content: center;
  text-align: center;
  font-weight: bold;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #ffcc0055;
  border-radius: 8px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in-out;
  padding: 0;
}

#header .pro-sidebar-inner .pro-sidebar-layout .inactive:hover {
  background-color: rgba(128, 128, 128, 0.096);
  border-radius: 8px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in-out;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active:hover {
  background-color: #ffffffcc;
  border-radius: 11px;
  margin-right: 12px;
  margin-left: 12px;
}

#header .logo {
  padding: 10px;
  margin-top: 1rem;
  background-color: #fff;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

.item-img {
  width: 20px;
  margin-right: 1rem;
}
.AppSidebar.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: static;
}
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 0.2rem;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0;
  padding-left: 1rem;
  margin: 0;
}
.pro-item-content {
  display: flex;
  align-items: center;
}
